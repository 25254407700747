import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"

const TequilaTrail = () => (
  <Layout>
    <SEO title="Tequila Trail" />
  
    <PostHero
        title="Tequila Trail"
        description="Relax and enjoy a margarita as we invite you to join us in Mexico"
        image={require('../images/tequila/teq1.png')}    />

      <div className="post-content">
        <p>I’m Carlos, a jimador, or farmer specializing in the harvest of agave, and also your guide today. Here’s your ride. Hop on. Let’s go to a field of Agave Azul Tequilana Weber. Of the more than 200 varieties of agave endemic to Mexico, by law that is the only type allowed to produce tequila.</p>
        <img src={require('../images/tequila/teq2.png')} alt=""/>
        <p>These agave were planted as little pups separated from their mother plants. Agave may also be reproduced through nectar-eating bat pollination. Now I will share with you the story of how the very first agave came to be. This tragic love story is an integral part of our mythical culture heritage.</p>
        <img src={require('../images/tequila/teq3.png')} alt=""/>
        <p>Once upon a time, there lived an Aztec god of life and creation known as Quetzalcoatl. He looked part feathered serpent and part dragon and flew between the earth and the sky. Quetzalcoatl fell in love with Mayahuel when he rescued her.</p>
        <img src={require('../images/tequila/teq4.png')} alt=""/>
        <p>You see, Mayahuel had been held prisoner by her grandmother, Tzintzimtl, an evil goddess who lived in the sky, devouring the earth’s light. She was furious when Quetzalcoatl took her granddaughter to live with him on earth. She vowed revenge and went to earth to find them. To hide from her, Quetzalcoatl and Mayahuel turned themselves into intertwining trees.</p>
        <img src={require('../images/tequila/teq5.png')} alt=""/>
        <p>Tzintzimtl discovered the lovers’ trick and destroyed the tree that was Mayahuel. Devastated, Quetzalcoatl killed Tzintzimtl. Light was restored to earth, but his love was gone forever. From the ground where Mayahuel had died and Quetzalcoatl had wept, grew the first agave.</p>
        <img src={require('../images/tequila/teq6.png')} alt=""/>
        <p>It is believed the fermented juice from that agave plant was the elixir that healed Quetzalcoatl’s broken heart. Now we believe agave’s distilled juice has many magical powers.</p>
        <p>After the agave has grown for at least five years, it is time harvest, or jima. We use a coa, a very sharp tool, to cut the leaves, or pincas, from the piña, the heart of the agave.</p>
        <p>The piñas are transported from the field to the distillery.</p>
        <p>At the distillery the piñas are cooked in a traditional or stainless steel oven.</p>
        <img src={require('../images/tequila/teq7.png')} alt=""/>
        <p>When they are cooked, the piñas look like this, with the fibrous texture of raw celery and a taste reminiscent of sweet potato.</p>
        <p>Historically, the juice of the cooked piñas was extracted in a process of crushing the agave by horses pulling a giant stone called a “tahona.” Today agave are crushed by machines. </p>
        <img src={require('../images/tequila/teq8-1.png')} alt=""/>
        <img src={require('../images/tequila/teq8-2.png')} alt=""/>
        <p>Water and yeast (additives or self-perpetuating) are added to the agave juice. The elixir ferments in wood or stainless steel tanks. Some distilleries even play music during a fermentation process.</p>
        <img src={require('../images/tequila/teq9.png')} alt=""/>
        <p>The remains of an ancient fermentation system remain today in the midst of an agave field not far from the town of Tequila. </p>
        <img src={require('../images/tequila/teq10-1.png')} alt=""/>
        <img src={require('../images/tequila/teq10-2.png')} alt=""/>
        <p>The fermented juice is distilled in a copper alambique or stainless steel column still. Most tequila is distilled twice. The heart of the juice is separated in the first distillation and sent through for the second distillation. </p>
        <p>After the second distillation is cooled through a condenser process: Tequila! It is clear, or blanco. If you prefer tequila aged, that happens in used whiskey barrels; 2 months but less than 12 months for reposado and 12 months to 3 years for anejo tequilas.  </p>
        <p>When the tequila is ready, the 100% agave product is bottled in Mexico and sent all over the world to enjoy...</p>
        <p>The <em>spirit</em> of the <em>spirit</em> of Mexico!</p>
      </div>

  </Layout>
)

export default TequilaTrail